import React from 'react';

export enum CookieConsentCategory {
  ESSENTIAL = 'essential',
  USER_ANALYTICS = 'user_analytics',
  ADVERTISING = 'advertising',
  PERSONALISED_ADVERTISING = 'personalised_advertising'
}
export const EditableCookieConsentCategory = {
  USER_ANALYTICS: CookieConsentCategory.USER_ANALYTICS,
  ADVERTISING: CookieConsentCategory.ADVERTISING,
  PERSONALISED_ADVERTISING: CookieConsentCategory.PERSONALISED_ADVERTISING
} as const;

export const COOKIE_BANNER_USER_PREF = 'cc_user_pref';
export const UTM_SOURCE = 'r2o_utm_source';
export const UTM_MEDIUM = 'r2o_utm_medium';
export const UTM_CAMPAIGN = 'r2o_utm_campaign';
export const R2O_REFERRER = 'r2o_referrer';
export const R2O_ENV = 'r2o_env';
export const R2O_OS = 'r2o_os';
export const AFF_NET = 'aff_net_id';

export type ConsentContextType = {
  acceptedCategories: Set<CookieConsentCategory>;
  setAcceptedCategories: React.Dispatch<React.SetStateAction<ConsentContextType['acceptedCategories']>>;
  setCookie: (name: string, value: string, cookieDomain?: string, setExpires?: boolean, days?: number) => void;
};
